<template>

    <div class="detail-wrap">
        <div class="page-header">
            <h3>添加微信</h3>
            <div class="actions">
                <span class="btn btn-light" @click="toList">返回列表</span>
            </div>
        </div>
        <WeixinForm v-model="data" @handleSubmit="handleSubmit"></WeixinForm>
    </div>
</template>


<script>
import { mapMutations } from 'vuex'

import WeixinForm from '../../components/WeixinForm.vue'

export default {
    name: 'WeixinNew',
    components: {
        WeixinForm
    },
    data() {
        return {
            data: {}
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        toList() {
            this.$router.push('/weixins')
        },
        handleSubmit(data, file) {
            this.startLoading()
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key])
            }
            if (file && file.files.length > 0) {
                formData.append('file', file.files[0])
            }
            this.$api.post('weixin/create', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                if (res.data.success) {
                    alert(res.data.message)
                    this.toList()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>