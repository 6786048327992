<template>
    <div class="form-items">
        <div class="form-item">
            <span class="form-label">订单内容:</span>
            <input type="text" v-model="data.des" class="form-control col">
            <span class="form-label">订单类型:</span>
            <Selection :items="orderTypes" placeholder="请选择订单类型" v-model="data.type" style-class="col"></Selection>
        </div>
        <div class="form-item" v-if="data.type == 8 || data.type == 7">
            <span class="form-label">客户姓名:</span>
            <input type="text" v-model="data.data.username" class="form-control col">
            <span class="form-label">客户性别:</span>
            <Selection :items="['女', '男']" placeholder="请选择性别" v-model="data.data.gender" style-class="col"></Selection>
        </div>
        <div class="form-item" v-if="data.type == 8 || data.type == 7">
            <span class="form-label">客户生辰:</span>
            <input type="text" ref="datetime" id="datetime" data-type="0" readonly="true" data-confirm="true"
                data-toid-date="date" data-toid-hour="hour" class="form-control col">
            <input type="hidden" id="date" v-model="date">
            <input type="hidden" id="hour" v-model="hour">
        </div>
        <div class="form-item" v-if="data.type == 2">
            <span class="form-label">男方姓名:</span>
            <input type="text" v-model="data.data.username" class="form-control col">
            <span class="form-label">女方姓名:</span>
            <input type="text" v-model="data.data.girl_username" class="form-control col">
        </div>
        <div class="form-item" v-if="data.type == 2">
            <span class="form-label">男方生辰:</span>
            <input type="datetime-local" ref="hehunBoyDateTime" v-model="hehunBoyDateTime" class="form-control col">
            <span class="form-label">女方生辰:</span>
            <input type="datetime-local" ref="hehunGirlDateTime" v-model="hehunGirlDateTime" class="form-control col">
        </div>
        <div class="form-item">
            <span class="form-label">订单状态:</span>
            <Selection :items="orderStatuses" placeholder="请选择状态" v-model="data.status" style-class="col">
            </Selection>
            <span class="form-label">支付渠道:</span>
            <Selection :items="payTypes" placeholder="请选择支付渠道" v-model="data.paytype" style-class="col">
            </Selection>
        </div>
        <div class="form-item">
            <span class="form-label">订单金额:</span>
            <input type="number" v-model="data.money" step="0.01" min="0.01" max="100" class="form-control col">
            <span class="form-label" v-if="!isNew">支付时间:</span>
            <input type="text" v-model="data.paytime" class="form-control col" v-if="!isNew">
        </div>
        <div class="form-item"
            v-if="orderAdAccount && (userInfo.role_permissions.includes('ADMIN') || userInfo.role_permissions.includes('AD_LEADER') || userInfo.role_permissions.includes('AD_OPTIMIZER'))">
            <span class="form-label">推广账户:</span>
            <Selection placeholder="请选择推广账户" v-model="data.ad_account_id" remote="ad_accounts" style-class="col">
            </Selection>
        </div>
        <div class="form-item"
            v-if="userInfo.role_permissions.includes('ADMIN') || userInfo.role_permissions.includes('WEIXIN_LEADER')">
            <span class="form-label">加微归属:</span>
            <Selection placeholder="无" v-model="data.weixin" :edit="true" remote="weixins" style-class="col">
            </Selection>
            <span class="form-label">微信组:</span>
            <Selection placeholder="无" v-model="data.weixin_group_id" remote="weixin_groups" style-class="col">
            </Selection>
        </div>
        <div class="form-item"
            v-if="userInfo.role_permissions.includes('ADMIN') || userInfo.role_permissions.includes('WEIXIN_LEADER') || userInfo.role_permissions.includes('WEIXIN_MEMBER') || userInfo.role_permissions.includes('ORDER_OPERATOR')">
            <span class="form-label">联系方式:</span>
            <input type="tel" v-model="data.contact" class="form-control col">
        </div>
    </div>
</template>


<script>
import { mapState } from 'vuex'

import Selection from './Selection.vue'

export default {
    name: 'OrderDetail',
    components: {
        Selection
    },
    props: {
        isNew: {
            type: Boolean,
            default: false
        },
        value: Object
    },
    computed: {
        ...mapState(['orderTypes', 'orderStatuses', 'payTypes', 'userInfo'])
    },
    data() {
        return {
            date: '',
            hour: '',
            hehunBoyDateTime: '',
            hehunGirlDateTime: '',
            orderAdAccount: process.env.VUE_APP_ORDER_AD_ACCOUNT === 'true',
            isInitDatepicker: false,
            data: this.value
        }
    },
    mounted() {
        if (this.data.type == 8 || this.data.type == 7) {
            this.initDatepicker()
        } else if (this.data.type == 2) {
            this.hehunBoyDateTime = this.getHehunDateTime(this.data.data.year, this.data.data.month, this.data.data.day, this.data.data.hour)
            this.hehunGirlDateTime = this.getHehunDateTime(this.data.data.girl_year, this.data.data.girl_month, this.data.data.girl_day, this.data.data.girl_hour)
        }
    },
    watch: {
        value(newVal) {
            this.data = newVal
            if (newVal.type == 8 || newVal.type == 7) {
                this.initDatepicker()
            } else if (newVal.type == 2) {
                this.hehunBoyDateTime = this.getHehunDateTime(this.data.data.year, this.data.data.month, this.data.data.day, this.data.data.hour)
                this.hehunGirlDateTime = this.getHehunDateTime(this.data.data.girl_year, this.data.data.girl_month, this.data.data.girl_day, this.data.data.girl_hour)
            }
        },
        'data.type'(newVal) {
            if (newVal == 8 || newVal == 7) {
                this.initDatepicker()
            }
        },
        date() {
            console.log(1);
        },
        hour() {
            console.log(2);
        }
    },
    methods: {
        getEncodeData() {
            if (this.data.type == 8 || this.data.type == 7) {
                let dataData = window.suanming(this.$refs.datetime.getAttribute("data-date"), this.$refs.datetime.getAttribute("data-hour"))
                dataData.username = this.data.data.username
                dataData.gender = this.data.data.gender
                return encodeURIComponent(JSON.stringify(dataData))
            } else if (this.data.type == 2) {
                const [date, time] = this.$refs.hehunBoyDateTime.value.split('T')
                const [year, month, day] = date.split('-')
                const [hour, _] = time.split(':')
                console.info(_)
                const [girl_date, girl_time] = this.$refs.hehunGirlDateTime.value.split('T')
                const [girl_year, girl_month, girl_day] = girl_date.split('-')
                const [girl_hour, __] = girl_time.split(':')
                console.info(__)
                let dataData = {}
                dataData.username = this.data.data.username
                dataData.year = parseInt(year)
                dataData.month = parseInt(month)
                dataData.day = parseInt(day)
                dataData.hour = parseInt(hour)
                dataData.girl_username = this.data.data.girl_username
                dataData.girl_year = parseInt(girl_year)
                dataData.girl_month = parseInt(girl_month)
                dataData.girl_day = parseInt(girl_day)
                dataData.girl_hour = parseInt(girl_hour)
                return encodeURIComponent(JSON.stringify(dataData))
            }
        },
        getHehunDateTime(y, m, d, h) {
            let ret_val = y + '-';
            if (parseInt(m) < 10) {
                ret_val += '0';
            }
            ret_val += m + '-';
            if (parseInt(d) < 10) {
                ret_val += '0';
            }
            ret_val += d + ' ';
            if (parseInt(h) < 10) {
                ret_val += '0';
            }
            ret_val += h + ':00';
            return ret_val
        },
        initDatepicker() {
            if (!this.isInitDatepicker) {
                this.$nextTick(() => {
                    if (this.data.data.y && this.data.data.m && this.data.data.d) {
                        let ymd = this.data.data.y + '-'
                        if (this.data.data.m < 10) {
                            ymd += '0'
                        }
                        ymd += this.data.data.m + "-"
                        if (this.data.data.d < 10) {
                            ymd += '0'
                        }
                        ymd += this.data.data.d
                        this.$refs.datetime.setAttribute('data-date', ymd)
                    }
                    if (this.data.data.h) {
                        this.$refs.datetime.setAttribute('data-hour', this.data.data.h)
                    }
                    new window.ruiDatepicker().init('#datetime')
                    if (this.data.data.lDate) {
                        this.$refs.datetime.setAttribute('value', this.data.data.lDate)
                    }
                })
                this.isInitDatepicker = true;
            }
        }
    }
}
</script>