<template>
    <div class="order-list">
        <div class="page-header">
            <h3>订单列表</h3>
            <div class="actions">
                <span class="btn btn-light" @click="handleNew">创建新订单</span>
            </div>
        </div>
        <form class="search" @submit.prevent="onSearch">
            <div class="search-item">
                <label>订单类型:</label>
                <Selection :items="orderTypes" placeholder="全部" v-model="query.type" width="120"></Selection>
            </div>
            <div class="search-item"
                v-if="orderAdAccount && (userInfo.role_permissions.includes('ADMIN') || userInfo.role_permissions.includes('AD_LEADER') || userInfo.role_permissions.includes('AD_OPTIMIZER'))">
                <label>推广账户:</label>
                <Selection placeholder="全部" v-model="query.ad_account_id" width="160" :edit="true" remote="ad_accounts">
                </Selection>
            </div>
            <div class="search-item" v-if="orderWeixinGroup">
                <label>微信组:</label>
                <Selection :items="weixinGroups" placeholder="全部" v-model="query.weixin_group_id" width="174">
                </Selection>
            </div>
            <div class="search-item" v-if="orderWeixinGroup">
                <label>微信号:</label>
                <Selection placeholder="全部" v-model="query.weixin" width="174" :edit="true" remote="weixins">
                </Selection>
            </div>
            <div class="clearfix"></div>
            <div class="search-item">
                <label>订单状态:</label>
                <Selection :items="['未付费', '已付费']" placeholder="全部" v-model="query.status" width="120">
                </Selection>
            </div>
            <div class="search-item">
                <label>开始时间:</label>
                <input type="date" class="form-control" v-model="query.start_date">
            </div>
            <div class="search-item">
                <label>结束时间:</label>
                <input type="date" class="form-control" v-model="query.end_date">
            </div>
            <div class="search-item">
                <label>关键词:</label>
                <input type="text" class="form-control kw" placeholder="如：订单号/手机号/客户姓名" v-model="query.kw">
            </div>
            <div class="search-item buttons">
                <button type="submit" class="btn btn-primary">查询</button>
                <span class="divider"></span>
                <button type="button" class="btn btn-light" @click="onClear">清空</button>
            </div>
        </form>
        <div class="table table-fixed">
            <table v-if="items.length > 0">
                <tr>
                    <th width="150" class="fixed-left">订单编号</th>
                    <th width="100">结果链接</th>
                    <th width="70" v-if="orderArea">地域</th>
                    <th width="230">交易单号</th>
                    <th width="200">订单内容</th>
                    <th width="50">性别</th>
                    <th width="140">生辰</th>
                    <th width="60">金额</th>
                    <th width="65">状态</th>
                    <th width="190">支付渠道</th>
                    <th width="90">广告标识</th>
                    <th width="160" v-if="orderAdAccount">推广账户</th>
                    <th width="140" v-if="orderAdAccount && orderKw">关键词</th>
                    <th width="200" v-if="orderAdAccount && orderKw">搜索词</th>
                    <th width="160" v-if="orderWeixinGroup">微信组</th>
                    <th width="200">加微归属</th>
                    <th width="200">客户微信</th>
                    <th width="200">接待微信</th>
                    <th width="140">时间</th>
                    <th width="60" class="fixed-right">操作</th>
                </tr>
                <tr v-for=" (item, i) in items" :key="i">
                    <td class="fixed-left">{{ item.oid }}</td>
                    <td>
                        <span class="badge badge-outline-success jieguo-link" v-clipboard:copy="item.v2_result_url"
                            v-if="item.status == 1">复制</span>
                        <span class="badge badge-outline-primary jieguo-link"
                            v-clipboard:copy="getJieguoLinkNew(item.oid)"
                            v-if="item.status == 1 && item.type == 8 && orderJieguoLinkNew">八字</span>
                    </td>
                    <td v-if="orderArea">{{ item.area }}</td>
                    <td>{{ item.trade_status }}</td>
                    <td>{{ item.des }}</td>
                    <td><span v-if="item.data">{{ getGender(item.data.gender) }}</span></td>
                    <td v-html="getGirthday(item.data)"></td>
                    <td>&yen;{{ item.money }}</td>
                    <td v-html="orderStatus(item.status)"></td>
                    <td>{{ item.pay_channel_name }}</td>
                    <td>{{ item.adtag }}</td>
                    <td v-if="orderAdAccount">{{ item.ad_account_username }}</td>
                    <td v-if="orderAdAccount && orderKw">{{ item.keyword }}</td>
                    <td v-if="orderAdAccount && orderKw">{{ item.search_word }}</td>
                    <td v-if="orderWeixinGroup">
                        <span class="badge text-bg-light" v-if="weixinGroups[item.weixin_group_id]">{{
                            weixinGroups[item.weixin_group_id] }}</span>
                    </td>
                    <td>{{ item.weixin }}</td>
                    <td><img :src="item.wx_avatar" class="avatar v-middle" v-if="item.wx_avatar"> <span class="v-middle">{{ item.wx_nickname }}</span></td>
                    <td>{{ item.cs_weixin }}</td>
                    <td>{{ formatDateYmdHis(item.createtime) }}</td>
                    <td class="fixed-right"><span class="btn btn-light btn-s"
                            @click="handleItemEdit(item.oid)">详情</span></td>
                </tr>
            </table>
        </div>
        <div class="page-info">
            <div class="operation" v-if="orderExport && total_count">
                <button class="btn btn-light btn-s" @click="handleDownload"
                    v-if="userInfo.role_permissions.includes('ADMIN') || userInfo.role_permissions.includes('AD_LEADER') || userInfo.role_permissions.includes('AD_OPTIMIZER')">下载数据</button>
            </div>
            <Pagination :total_count="total_count" :current_page="query.page" :page_size="query.page_size"
                @handlePage="handlePage" @handlePageSize="handlePageSize">
            </Pagination>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'
import Selection from '../../components/Selection.vue'

export default {
    name: 'OrderList',
    components: {
        Pagination,
        Selection
    },
    props: {
        msg: String
    },
    computed: {
        ...mapState(['orderTypes', 'userInfo'])
    },
    mounted() {
        this.fetchWeixinGroups()
        this.orderWeixinGroup = this.userInfo.role_permissions.includes('ADMIN') || this.userInfo.role_permissions.includes('WEIXIN_LEADER')
        this.orderAdAccount = process.env.VUE_APP_ORDER_AD_ACCOUNT === 'true' && (this.userInfo.role_permissions.includes('ADMIN') || this.userInfo.role_permissions.includes('AD_LEADER') || this.userInfo.role_permissions.includes('AD_OPTIMIZER'));
        this.query = { ...this.getDefaultQuery(), ...this.$route.query }
        this.fetchList()
    },
    data() {
        return {
            weixinGroups: [],
            orderJieguoLink: process.env.VUE_APP_ORDER_JIEGUO_LINK,
            orderJieguoLinkNew: process.env.VUE_APP_ORDER_JIEGUO_LINK_NEW,
            orderAdAccount: false,
            orderExport: process.env.VUE_APP_ORDER_EXPORT === 'true',
            orderArea: process.env.VUE_APP_ORDER_AREA === 'true',
            orderKw: process.env.VUE_APP_ORDER_KW === 'true',
            orderWeixinGroup: false,
            apiBase: process.env.VUE_APP_API_BASE,
            query: {},
            page: 1,
            total_count: 0,
            items: [],
            itemData: null
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        getDefaultQuery() {
            let query = {
                page: 1,
                page_size: 10
            }
            const now = new Date()
            query.end_date = query.start_date = this.formatDateYmd(now)
            return query
        },
        onClear() {
            this.$router.push({ query: {} })
            this.query = this.getDefaultQuery()
            this.fetchList()
        },
        onSearch() {
            this.query.page = 1
            this.$router.push({ query: this.query })
            this.fetchList()
        },
        fetchList() {
            this.startLoading()
            this.$api.post('order/list', this.query).then(res => {
                if (res.data.success) {
                    let items = []
                    for (let item of res.data.data.items) {
                        if (item.data != null) {
                            item.data = JSON.parse(decodeURIComponent(item.data))
                        }
                        items.push(item)
                    }
                    this.items = items
                    this.total_count = res.data.data.total_count
                    this.query.page = res.data.data.current_page
                    this.query.page_size = res.data.data.page_size
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        fetchWeixinGroups() {
            this.$api.get('weixin_groups').then(res => {
                if (res.data.success) {
                    this.weixinGroups = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        handleItemEdit(oid) {
            this.$router.push('/orders/' + oid + '/edit')
        },
        handleDialogClose() {
            this.itemData = null
        },
        handlePage(page) {
            this.query.page = page
            this.$router.push({ query: this.query })
            this.fetchList()
        },
        handlePageSize(pageSize) {
            this.query.page_size = pageSize
            this.$router.push({ query: { ...this.query, ...{ page: 1 } } })
            this.fetchList()
        },
        formatDateYmd(date) {
            let str = date.getFullYear() + "-"
            if (date.getMonth() < 9) {
                str += "0"
            }
            str += (date.getMonth() + 1) + "-"
            if (date.getDate() < 10) {
                str += "0"
            }
            return str + date.getDate()
        },
        orderStatus(status) {
            if (status == 1) {
                return '<span class="badge text-bg-success">已付费</span>'
            }
            return '<span class="badge text-bg-light">待付费</span>'
        },
        formatDateYmdHis(timestamp) {
            const date = new Date(timestamp * 1000)
            let str = date.getFullYear() + "-"
            if (date.getMonth() < 9) {
                str += "0"
            }
            str += (date.getMonth() + 1) + "-"
            if (date.getDate() < 10) {
                str += "0"
            }
            str += date.getDate() + " "
            if (date.getHours() < 10) {
                str += "0"
            }
            str += date.getHours() + ":"
            if (date.getMinutes() < 10) {
                str += "0"
            }
            str += date.getMinutes() + ":"
            if (date.getSeconds() < 10) {
                str += "0"
            }
            return str + date.getSeconds()
        },
        getJieguoLink(oid) {
            return this.orderJieguoLink.replace('{{oid}}', oid)
        },
        getJieguoLinkNew(oid) {
            return this.orderJieguoLinkNew.replace('{{oid}}', oid)
        },
        getGender(gender) {
            if (gender == 0) {
                return '女';
            } else if (gender == 1) {
                return '男';
            }
            return '';
        },
        getGirthday(itemData) {
            if (itemData != null) {
                if (itemData.girl_year) {
                    return '男: ' + itemData.year + '-' + this.padSero(itemData.month) + '-' + this.padSero(itemData.day) + ' ' + this.padSero(itemData.hour) + '时<br>女: ' + this.padSero(itemData.girl_year) + '-' + this.padSero(itemData.girl_month) + '-' + this.padSero(itemData.girl_day) + ' ' + this.padSero(itemData.girl_hour) + '时<br>'
                } else if (itemData.year) {
                    return this.padSero(itemData.year) + '-' + this.padSero(itemData.month) + '-' + this.padSero(itemData.day) + ' ' + this.padSero(itemData.hour) + '时'
                } else if (itemData.y) {
                    return this.padSero(itemData.y) + '-' + this.padSero(itemData.m) + '-' + this.padSero(itemData.d) + ' ' + this.padSero(itemData.h) + '时'
                }
            }
            return '';
        },
        padSero(val) {
            if (parseInt(val) < 10) {
                return '0' + val;
            }
            return val
        },
        handleDownload() {
            let url = this.apiBase + 'order/export?token=' + encodeURIComponent(localStorage.getItem('token'));
            for (let key in this.query) {
                url += '&' + key + "=" + encodeURIComponent(this.query[key]);
            }
            window.location.href = url;
        },
        handleNew() {
            this.$router.push('/orders/new')
        },
    }
}
</script>