<template>
    <div>
        <div class="wrapper">
            <div class="order-list">
                <div class="page-header">
                    <h3>账户列表</h3>
                    <div class="actions">
                        <span class="btn btn-light" @click="handleNew">添加账户</span>
                    </div>
                </div>
                <form class="search" @submit.prevent="onSearch">
                    <div class="search-item">
                        <label>是否启用:</label>
                        <Selection :items="['未启用', '已启用']" placeholder="全部" v-model="query.is_enabled" width="120">
                        </Selection>
                    </div>
                    <div class="search-item" v-if="!userInfo.role_permissions.includes('AD_LEADER') && !userInfo.role_permissions.includes('AD_OPTIMIZER')">
                        <label>微信组:</label>
                        <Selection placeholder="全部" :items="weixinGroups" v-model="query.weixin_group_id"></Selection>
                    </div>
                    <div class="search-item"><label>关键词:</label>
                        <input type="text" class="form-control kw" placeholder="如：账户名" v-model="query.kw">
                    </div>
                    <div class="search-item buttons">
                        <button type="submit" class="btn btn-primary">查询</button>
                        <span class="divider"></span>
                        <button type="button" class="btn btn-light" @click="onClear">清空</button>
                    </div>
                </form>
                <div class="table table-fixed">
                    <table v-if="items.length > 0">
                        <tr>
                            <th width="220" class="fixed-left">账户名</th>
                            <th width="100">媒体类型</th>
                            <th width="80">是否启用</th>
                            <th width="110">当前余额</th>
                            <th width="180">归属运营</th>
                            <th width="180" v-if="!userInfo.role_permissions.includes('AD_LEADER') && !userInfo.role_permissions.includes('AD_OPTIMIZER')">微信组</th>
                            <th width="80">数据同步</th>
                            <th width="130">创建时间</th>
                            <th width="244" class="fixed-right">操作</th>
                        </tr>
                        <tr v-for=" (item, i) in items" :key="i">
                            <td class="fixed-left">
                                <div class="v-middle">
                                    {{ item.username }}
                                    <p v-if="item.media_account_id">
                                        <small class="text-gray">ID:{{ item.media_account_id }}</small>
                                    </p>
                                </div>
                            </td>
                            <td>{{ item.media_type }}</td>
                            <td>{{ item.is_enabled ? '已启用' : '未启用' }}</td>
                            <td>
                                <span class="v-middle">{{ item.cookie ? item.balance : '' }}</span>
                            </td>
                            <td>
                                <span v-for="(member_id, member_index) in item.member_ids" :key="member_index">{{
                            members[member_id] }}</span>
                            </td>
                            <td v-if="!userInfo.role_permissions.includes('AD_LEADER') && !userInfo.role_permissions.includes('AD_OPTIMIZER')">
                                <span class="badge text-bg-light"
                                    v-for="(weixin_group_id, weixin_group_index) in item.weixin_group_ids" :key="weixin_group_index">{{
                            weixinGroups[weixin_group_id] }}</span>
                            </td>
                            <td>{{ item.cookie ? '已配置' : '' }}</td>
                            <td>{{ item.created_at }}</td>
                            <td class="fixed-right"><span class="btn btn-light btn-s"
                                    @click="handleAdTagClick(item.id)">后缀</span> <span class="btn btn-light btn-s"
                                    @click="handleItemDiscount(item.id)">折扣</span> <span class="btn btn-light btn-s"
                                    @click="handleItemEdit(item.id)">编辑</span> <span class="btn btn-light btn-s"
                                    @click="handleItemDelete(item)">删除</span></td>
                        </tr>
                    </table>
                </div>
                <div class="page-info">
                    <Pagination :total_count="total_count" :current_page="query.page" :page_size="query.page_size"
                        @handlePage="handlePage"></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'
import Selection from '../../components/Selection.vue'

export default {
    name: 'OrderList',
    components: {
        Pagination,
        Selection
    },
    props: {
        msg: String
    },
    computed: {
        ...mapState(['userInfo'])
    },
    mounted() {
        this.fetchMembers()
        this.fetchWeixinGroups()
        this.query = { ...this.getDefaultQuery(), ...this.$route.query }
        this.fetchList(this.query.page)
    },
    data() {
        return {
            query: {
                is_enabled: 1,
                weixin_group_id: '',
                kw: '',
                page: 1,
                page_size: 10
            },
            page: 1,
            weixinGroups: [],
            members: [],
            total_count: 0,
            items: [],
            itemData: null,
            isLoading: false,
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        getDefaultQuery() {
            return {
                is_enabled: 1,
                weixin_group_id: '',
                kw: '',
                page: 1,
                page_size: 10
            }
        },
        onClear() {
            this.query = this.getDefaultQuery()
            this.$router.push({ query: {} })
            this.fetchList()
        },
        onSearch() {
            this.$router.push({ query: { ...this.query, ...{ page: 1 } } })
            this.fetchList()
        },
        fetchList(page = 1) {
            this.startLoading()
            this.$api.post('ad_account/list', { ...this.query, ...{ page: page } }).then(res => {
                if (res.data.success) {
                    this.items = []
                    for (let item of res.data.data.items) {
                        if (item.member_ids) {
                            item.member_ids = item.member_ids.split(',')
                        } else {
                            item.member_ids = []
                        }
                        if (item.balance_ad_account_ids) {
                            item.balance_ad_account_ids = item.balance_ad_account_ids.split(',')
                        } else {
                            item.balance_ad_account_ids = []
                        }
                        if (item.weixin_group_ids) {
                            item.weixin_group_ids = item.weixin_group_ids.split(',')
                        } else {
                            item.weixin_group_ids = []
                        }
                        this.items.push(item)
                    }
                    this.total_count = res.data.data.total_count
                    this.query.page = res.data.data.current_page
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        fetchMembers() {
            this.$api.get('members', this.query).then(res => {
                if (res.data.success) {
                    this.members = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        fetchWeixinGroups() {
            this.$api.get('weixin_groups', this.query).then(res => {
                if (res.data.success) {
                    this.weixinGroups = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        onItemClick(item) {
            this.itemData = item
        },
        handleDialogClose() {
            this.itemData = null
        },
        handlePage(page) {
            this.$router.push({ query: { ...this.query, ...{ page: page } } })
            this.fetchList(page)
        },
        handleAdTagClick(id) {
            this.$router.push({ path: '/ad_tags', query: { ad_account_id: id } })
        },
        handleNew() {
            this.$router.push('/ad_accounts/new')
        },
        handleItemEdit(id) {
            this.$router.push('/ad_accounts/' + id + '/edit')
        },
        handleItemDiscount(id) {
            this.$router.push('/ad_accounts/' + id + '/discounts')
        },
        handleItemDelete(item) {
            if (confirm('确定要删除用户【' + item.username + '】吗？')) {
                this.$api.post('ad_account/delete', { id: item.id }).then(res => {
                    if (res.data.success) {
                        alert(res.data.message)
                        this.fetchList(this.page)
                    } else {
                        alert(res.data.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
    }
}
</script>
