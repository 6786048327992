<template>
    <div class="form">
        <form @submit.prevent="handleSubmit">
            <div class="form-item">
                <span class="form-label">微信组名称:</span>
                <input type="text" v-model="data.name" class="form-control col">
                <span class="form-label">微信客服:</span>
                <Selection :items="weixinMps" placeholder="请选择微信客服" v-model="data.weixin_kf_id" style="col">
                </Selection>
            </div>
            <div class="form-item" v-if="weixinGroupLeader">
                <span class="form-label">微信组成员:</span>
                <ul class="children col">
                    <li v-for="(item, index) in members" :key="index" class="form-check">
                        <label><input type="checkbox" v-model="data.member_ids" :value="index">{{ item }}</label>
                    </li>
                </ul>
                <span class="form-label">售卖价格:</span>
                <input type="text" v-model="data.moneys" class="form-control col">
            </div>
            <div class="form-item">
                <span class="form-label">上线时段:</span>
                <div class="col2">
                    <table class="hours" @mouseup="stopDrawing" @mouseleave="stopDrawing">
                        <tr>
                            <th></th>
                            <th v-for="(n, h) in Array.from({ length: 24 }, (_, h) => h + 1)" :key="h">
                                {{ String(h).padStart(2, '0') }}
                            </th>
                        </tr>
                        <tr v-for="(hours, rowIndex) in workHours" :key="rowIndex">
                            <th>{{ works[rowIndex] }}</th>
                            <td v-for="(bool, cellIndex) in hours" :key="cellIndex" :class="{ active: bool }"
                                @mousedown="handleMouseDown(rowIndex, cellIndex)"
                                @mouseover="toggleCell(rowIndex, cellIndex)">
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="footing">
                <button type="submit" class="btn btn-primary">保存</button>
            </div>
        </form>
    </div>
</template>


<script>
import Selection from './Selection.vue'

export default {
    name: 'WeixinGroupForm',
    components: {
        Selection
    },
    props: {
        value: Object
    },
    data() {
        return {
            weixinGroupLeader: process.env.VUE_APP_WEIXIN_GROUP_LEADER === 'true',
            members: [],
            works: ['日', '一', '二', '三', '四', '五', '六'],
            workHours: [],
            weixinMps: [],
            isDrawing: false,
            data: this.value
        }
    },
    mounted() {
        this.fetchMembers()
        this.fetchWeixinMps()
    },
    methods: {
        fetchMembers() {
            this.$api.get('members', { params: { permissions: 'WEIXIN_LEADER;WEIXIN_MEMBER' } }).then(res => {
                if (res.data.success) {
                    this.members = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        fetchWeixinMps() {
            this.$api.get('weixin_kfs', this.query).then(res => {
                if (res.data.success) {
                    this.weixinMps = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        handleSubmit() {
            let work_hours = ''
            for (let row = 0; row < this.workHours.length; row++) {
                for (let cell = 0; cell < this.workHours[row].length; cell++) {
                    work_hours += this.workHours[row][cell] ? '1' : '0'
                }
            }
            this.$set(this.data, 'work_hours', work_hours)
            this.$emit('handleSubmit', this.data)
            return false
        },
        handleMouseDown(rowIndex, cellIndex) {
            if (event.buttons === 1) { // 检查是否是左键点击
                this.isDrawing = true;
                this.toggleCell(rowIndex, cellIndex); // 立即修改当前单元格
            }
        },
        stopDrawing() {
            this.isDrawing = false;
        },
        toggleCell(rowIndex, cellIndex) {
            if (this.isDrawing) {
                this.$set(this.workHours[rowIndex], cellIndex, !this.workHours[rowIndex][cellIndex])
            }
        },
        handleCell(rowIndex, cellIndex) {
            this.$set(this.workHours[rowIndex], cellIndex, !this.workHours[rowIndex][cellIndex])
        }
    },
    watch: {
        value(newVal) {
            this.data = newVal
            this.workHours = [
                [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
                [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
                [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
                [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
                [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
                [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
                [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true]
            ]
            for (let i = 0; i < newVal.work_hours.length; i += 24) {
                let rowIndex = Math.floor(i / 24);
                for (let cellIndex = 0; cellIndex < 24; cellIndex++) {
                    if (newVal.work_hours[i + cellIndex] == '0') {
                        this.$set(this.workHours[rowIndex], cellIndex, false)
                    }
                }
            }
        }
    }
}
</script>